import { Injectable } from '@angular/core';
import { ApiResponse, Feedback, Organization } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService extends BaseService {
  protected override baseName: string = 'Feedbacks';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Feedback[]>>('Feedbacks', {
      params: this.convertToHttpParams(filter),
    });
  }
  exportToCsv(filter: Record<string, unknown>) {
    return this.httpClient.get(`${this.baseName}/exportToCsv/`, {
      responseType: 'blob',
      observe: 'response',
      params: this.convertToHttpParams(filter),
    });
  }
  archive(id: Feedback['id'], organizationId: Organization['id']) {
    return this.httpClient.patch(
      `${this.baseName}/${organizationId}/archive/${id}`,
      {}
    );
  }
}
