import { Injectable } from '@angular/core';
import { ApiResponse, Organization, TestDrive } from '@models';
import { BaseService } from '@utils';

@Injectable()
export class TestDriveService extends BaseService {
  protected override baseName = 'Registrations';

  get(filter: Record<string, unknown>) {
    const testData = this.httpClient.get<ApiResponse<TestDrive[]>>(
      `${this.baseName}`,
      {
        params: this.convertToHttpParams(filter),
      }
    );

    return testData;
  }

  exportToCsv(
    organizationId: Organization['id'],
    filter: Record<string, unknown>
  ) {
    return this.httpClient.get(
      `${this.baseName}/exportToCsv/${organizationId}`,
      {
        responseType: 'blob',
        observe: 'response',
        params: this.convertToHttpParams(filter),
      }
    );
  }
  resolveTestDrive(ids: TestDrive['id'][]) {
    return this.httpClient.patch(`${this.baseName}/resolveTestDrive`, {
      ids,
    });
  }
}
