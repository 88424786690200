import { Injectable } from '@angular/core';
import { ApiResponse, Organization, User } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  protected override baseName: string = 'Users';
  getAll(organizationId: Organization['id'], filter?: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<User[]>>(
      `Organizations/${organizationId}/users`,
      {
        params: this.convertToHttpParams(
          filter ? { ...filter, limit: 1000 } : { limit: 1000 }
        ),
      }
    );
  }
  add(data: User) {
    return this.httpClient.post<User>(`Users`, data);
  }
  deleteById(id: User['id'], organizationId: Organization['id']) {
    return this.httpClient.delete<User>(
      `Users/${organizationId}/deleteuser/${id}`
    );
  }

  updateById(
    data: Partial<User>,
    id: User['id'],
    organizationId: Organization['id']
  ) {
    return this.httpClient.put<User>(
      `Organizations/${organizationId}/users/${id}`,
      data
    );
  }
}
