import { Injectable } from '@angular/core';
import { ApiResponse, Complaint, Organization } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class ComplaintService extends BaseService {
  protected override baseName = 'Complaints';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Complaint[]>>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }

  resolve(data: { ids: string; status: boolean }) {
    return this.httpClient.get(`${this.baseName}/updateStatus`, {
      params: this.convertToHttpParams(data),
    });
  }
  exportToCsv(filter: Record<string, unknown>) {
    return this.httpClient.get(`${this.baseName}/exportToCsv/`, {
      responseType: 'blob',
      observe: 'response',
      params: this.convertToHttpParams(filter),
    });
  }
  archive(id: Complaint['id'], organizationId: Organization['id']) {
    return this.httpClient.patch(
      `${this.baseName}/${organizationId}/archive/${id}`,
      {}
    );
  }
}
