import { Injectable } from '@angular/core';
import { ApiResponse, Group, Organization, User, GroupMember } from '@models';
import { BaseService } from '@utils';

@Injectable()
export class GroupService extends BaseService {
  protected override baseName: string = 'groups';
  get(organizationId: Organization['id']) {
    return this.httpClient.get<ApiResponse<Group[]>>(
      `Organizations/${organizationId}/groups`
    );
  }
  public update(id: string, data: Partial<Group>) {
    return this.httpClient.put<Group>(`${this.baseName}/${id}`, data);
  }
  public delete(groupId: string) {
    return this.httpClient.delete(`${this.baseName}/${groupId}`);
  }

  add(data: Group, organizationId: Organization['id']) {
    return this.httpClient.post<Group>(
      `Organizations/${organizationId}/groups`,
      data
    );
  }

  addMember(data: Pick<GroupMember, 'userId' | 'groupId'>[]) {
    // Create an object with userId and groupId
    const postData = data.map(ele => {
      return {
        userId: ele.userId,
        groupId: ele.groupId,
      };
    });
    return this.httpClient.post<GroupMember[]>(
      'groupMembers/bulkInsert',
      postData
    );
  }

  deleteMember(id: GroupMember['id']) {
    return this.httpClient.delete(`groupMembers/${id}`);
  }
  getGroupMembers(groupId: Group['id']) {
    return this.httpClient.get<GroupMember[]>(
      `groupMembers/${groupId}/members`
    );
  }
  dissociateMember(id: Organization['id']) {
    return this.httpClient.get<User[]>(`Users/${id}/dissociateMember`, {
      params: this.convertToHttpParams({ isGroup: true }),
    });
  }
}
