import { Injectable } from '@angular/core';
import { Offer } from '@models';
import { BaseService } from '@utils';

@Injectable({ providedIn: 'root' })
export class OfferService extends BaseService {
  protected override baseName: string = 'Offers';
  get(filter?: Record<string, unknown>) {
    return this.httpClient.get<{
      offer: Offer[];
    }>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }
  update(id: Offer['id'], data: FormData) {
    return this.httpClient.put<Offer>(`${this.baseName}/${id}/update`, data);
  }
  delete(id: Offer['id']) {
    return this.httpClient.delete(`${this.baseName}/${id}`);
  }

  toggleStatus(id: Offer['id'], data: Pick<Offer, 'status'>) {
    return this.httpClient.put<{ success: boolean; offer: Offer }>(
      `Offers/${id}/change`,
      data
    );
  }
  archive(id: Offer['id']) {
    return this.httpClient.patch(`Offers/${id}/archive`, {});
  }
  create(data: FormData) {
    return this.httpClient.post<Offer>(`Offers/create`, data);
  }
}
