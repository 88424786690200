import { Injectable } from '@angular/core';
import { ApiResponse, Query, Organization } from '@models';
import { QueryPageModel } from '@models/pages';
import { BaseService } from '@utils';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class QueryService extends BaseService {
  get(filter: QueryPageModel.filter) {
    return this.httpClient.get<ApiResponse<Query[]>>('Queries', {
      params: this.convertToHttpParams(filter),
    });
  }
  exportToCsv(filter: Record<string, unknown>) {
    return this.httpClient.post('Queries/export', filter, {
      responseType: 'blob',
      observe: 'response',
    });
  }
  updateLabel(filter: Record<string, unknown>) {
    return this.httpClient.patch('/Queries/refreshLabel', filter);
  }
  getIntentIds(
    organizationId: Organization['id'],
    startDate?: string,
    endDate?: string
  ) {
    const params = new HttpParams()
      .set('startDate', startDate || '')
      .set('endDate', endDate || '');

    return this.httpClient.get<{ intentIds: string[] }>(
      `Queries/getIntentIds/${organizationId}`,
      { params }
    );
  }
}
