import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Auth } from '@stores/auth/auth.state';
import { LoginCredential } from '@models';
import { LoginResponse } from '@models/LoginResponse';
import { LocalStorage } from '@utils';
interface Token {
  token: Auth['token'];
  organizationId?: Auth['organization']['id'];
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly STORAGE_KEY = 'token';
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorage
  ) {}
  getSettings(token: string, organizationId?: string) {
    let params = new HttpParams().set('access_token', token);
    if (organizationId) {
      params = params.set('orgId', organizationId);
    }
    return this.httpClient.get<Omit<Auth, 'token'>>(`settings/authUser`, {
      params,
    });
  }
  updateSettings(data: Omit<Auth['setting'], 'header'>) {
    return this.httpClient.patch<typeof data>(`settings/${data.id}`, data);
  }
  login(credential: LoginCredential) {
    return this.httpClient.post<LoginResponse>('Users/login', credential);
  }
  saveToken(value: Token) {
    this.localStorage.set(this.STORAGE_KEY, value);
  }
  deleteToken() {
    this.localStorage.del(this.STORAGE_KEY);
  }
  logOut() {
    return this.httpClient.post('Users/logout', {});
  }
  getToken() {
    return this.localStorage.get<Token>(this.STORAGE_KEY, undefined);
  }
  changePassword(oldPassword: string, newPassword: string) {
    return this.httpClient.post('Users/change-password', {
      oldPassword,
      newPassword,
    });
  }
}
