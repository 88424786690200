import { Injectable } from '@angular/core';
import { ApiResponse, Group, Lead, Organization } from '@models';
import { BaseService } from '@utils';

@Injectable()
export class LeadService extends BaseService {
  protected override baseName: string = 'Leads';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Lead[]>>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }
  exportToCsv(
    organizationId: Organization['id'],
    filter: Record<string, unknown>
  ) {
    return this.httpClient.get(
      `${this.baseName}/exportToCsv/${organizationId}`,
      {
        responseType: 'blob',
        observe: 'response',
        params: this.convertToHttpParams(filter),
      }
    );
  }
  getInterest(organizationId: Organization['id']) {
    return this.httpClient.get<{ dropper: string[]; source: string[] }>(
      `${this.baseName}/interestAndSource/${organizationId}`
    );
  }
  addToGroup(ids: Lead['id'][], groupId: Group['id']) {
    return this.httpClient.post(`groups/${groupId}/group-leads`, {
      groupId,
      leadId: ids.join(','),
    });
  }
  sendMessage(body: FormData) {
    return this.httpClient.post<{ message: string }>(
      `${this.baseName}/sendMessage`,
      body
    );
  }
}
