import { Injectable } from '@angular/core';
import { AgentPageModel, ApiResponse } from '@models';

import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends BaseService {
  getAnalytics(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<AgentPageModel.AgentAnalytics>>(
      'sessions/agentDetails',
      {
        params: this.convertToHttpParams(filter),
      }
    );
  }

  exportToCsv(filter: Record<string, unknown>) {
    return this.httpClient.get('sessions/agentDetails/exports', {
      params: this.convertToHttpParams(filter),
      responseType: 'blob',
      observe: 'response',
    });
  }
}
