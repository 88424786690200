import { Injectable } from '@angular/core';
import { ApiLog, Organization } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class ApiLogService extends BaseService {
  protected override baseName: string = 'apiLogs';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiLog[]>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }
  getDropper(organizationId: Organization['id']) {
    return this.httpClient.get<{
      success: boolean;
      type: string[];
      category: string[];
    }>(`${this.baseName}/${organizationId}/droper`);
  }
  exportToCsv(
    filter: Record<string, unknown>,
    organizationId: Organization['id']
  ) {
    return this.httpClient.get(`${this.baseName}/${organizationId}/export`, {
      params: this.convertToHttpParams(filter),
      responseType: 'blob',
      observe: 'response',
    });
  }
}
