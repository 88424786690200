import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';

@Injectable()
export class ResetService {
  constructor(private httpClient: HttpClient) {}
  resetPassword({ email }: { email: string }) {
    return this.httpClient.post('Users/reset', {
      email,
      callBackUrl: `${environment.host}/#/reset-password`,
    });
  }
  setResetPassword({
    newPassword,
    token,
  }: {
    newPassword: string;
    token: string;
  }) {
    return this.httpClient.post(`Users/reset-password?access_token=${token}`, {
      newPassword,
    });
  }
}
