import { Injectable } from '@angular/core';
import { ApiResponse, Department, Organization, User } from '@models';
import { BaseService } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService extends BaseService {
  protected override baseName: string = 'departments';
  public get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Department[]>>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }
  public delete(id: string) {
    return this.httpClient.delete(`${this.baseName}/${id}`);
  }
  add(data: Department) {
    return this.httpClient.post<Department>(`${this.baseName}`, data);
  }
  public update(id: string, data: Partial<Department>) {
    return this.httpClient.patch<Department>(`${this.baseName}/${id}`, data);
  }
  addMember(id: string, userId: string) {
    return this.httpClient.post<Department['members'][0]>(
      `${this.baseName}/${id}/members`,
      {
        departmentId: id,
        userId: userId,
      }
    );
  }
  deleteMember(id: string) {
    return this.httpClient.delete(`departmentMembers/${id}`);
  }
  dissociateMember(id: Organization['id']) {
    return this.httpClient.get<User[]>(`Users/${id}/dissociateMember`);
  }
}
