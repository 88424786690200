import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, Organization } from '@models';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private readonly baseUrl = 'Organizations';
  constructor(private httpClient: HttpClient) {}
  get() {
    return this.httpClient.get<ApiResponse<Organization[]>>(`${this.baseUrl}`);
  }
  update(data: FormData, id: Organization['id']) {
    return this.httpClient.patch<Organization>(
      `${this.baseUrl}/${id}/update`,
      data
    );
  }
  add(data: FormData) {
    return this.httpClient.post<Organization>(`${this.baseUrl}/create`, data);
  }
  clear(id: Organization['id']) {
    return this.httpClient.delete(`${this.baseUrl}/${id}/clean`);
  }
}
