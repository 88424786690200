import { Injectable } from '@angular/core';
import { ApiResponse, Appointment, Organization } from '@models';
import { BaseService } from '@utils';
@Injectable({ providedIn: 'root' })
export class AppointmentService extends BaseService {
  protected override baseName: string = 'schedulers';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Appointment[]>>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }

  confirm(
    id: Appointment['id'],
    organizationId: Organization['id'],
    appointmentDate?: string
  ) {
    return this.httpClient.patch(
      `${this.baseName}/${organizationId}/confirm/${id}`,
      { appointmentDate }
    );
  }

  archive(id: Appointment['id'], organizationId: Organization['id']) {
    return this.httpClient.patch(
      `${this.baseName}/${organizationId}/archive/${id}`,
      {}
    );
  }
}
