import { Injectable } from '@angular/core';
import {
  ApiResponse,
  Campaign,
  DateRangePickerModel,
  Organization,
} from '@models';
import { BaseService } from '@utils';

@Injectable({ providedIn: 'root' })
export class CampaignService extends BaseService {
  protected override baseName: string = 'Campaigns';
  get(filter: Record<string, unknown>) {
    return this.httpClient.get<ApiResponse<Campaign[]>>(`${this.baseName}`, {
      params: this.convertToHttpParams(filter),
    });
  }
  create(data: FormData) {
    return this.httpClient.post<Campaign>(`${this.baseName}/create`, data);
  }

  update(id: Campaign['id'], data: FormData) {
    return this.httpClient.patch<Campaign>(
      `${this.baseName}/` + id + '/update',
      data
    );
  }
  archive(id: string) {
    return this.httpClient.patch(`${this.baseName}/archive/${id}`, {});
  }

  delete(id: string) {
    return this.httpClient.delete(`${this.baseName}/${id}`);
  }
  send(
    body: DateRangePickerModel.DateFilter & {
      organizationId: Organization['id'];
    },
    campaignId: Campaign['id']
  ) {
    return this.httpClient.post(`${this.baseName}/send/${campaignId}`, body);
  }
}
